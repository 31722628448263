import { Form, Formik, FormikProps } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useMemo, useRef, useState } from "react";
import JDropdown from "../../../../base/JDropdown/JDropdown";
import JInputText from "../../../../base/JInputText";
import JTextArea from "../../../../base/JTextArea/JTextArea";
import { useMessagesContext } from "../../MessagesContext";
import { MessageSchema } from "../../schemas";
import { JMessageModalParams } from "../../types";
import { useUserContext } from "../../../Users/UserContext";
import { toast } from "react-toastify";
import { getFormikFromRef, isGroupableEvent } from "@/utils";
import { useEventsContext } from "@/containers/Events/EventsContext";
import { Checkbox } from "primereact/checkbox";
import { Asset, DropdownOption } from "@/types";
import SelectGifDialog from "@/components/SelectGifDialog";
import { classNames } from "primereact/utils";
import { Image } from "primereact/image";

interface FormProps {
  title: string;
  message: string;
  eventId: string;
  isGroup: boolean;
  role?: string | null;
  prefferedAsset: Asset | null;
}

const MessageModal = ({
  message,
  onClose,
  eventId,
}: {
  message: JMessageModalParams | null;
  onClose: (eventId?: string) => void;
  eventId: string;
}) => {
  const formikRef = useRef<FormikProps<FormProps>>(null);

  const { generateContent, isPromptFetching, prompts, getPrompts } = useUserContext();
  const { getMessages, postMessage, patchMessage, isFetching } = useMessagesContext();
  const { events } = useEventsContext();
  const [showSelectGif, setShowSelectGif] = useState(false);

  const isNew = !message;

  const promptsRoles: DropdownOption[] = useMemo(
    () =>
      prompts
        .filter((p) => p.event.id === eventId)
        .map((p) => ({ id: p.role, name: p.role }))
        .filter((f) => f.name),
    [eventId, prompts]
  );

  useEffect(() => {
    getPrompts({
      shared: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: Omit<JMessageModalParams, "id">) => {
    if (!eventId) {
      toast.error("No type selected");
      return;
    }

    try {
      if (isNew) {
        await postMessage({
          ...values,
        });
        toast.success("Message added");
        return;
      }

      await patchMessage({
        ...values,
        id: message.id,
      });
      toast.success("Message updated");
    } catch (err) {
      console.error(err);
      toast.error(err);
    } finally {
      await getMessages(values.eventId);
      onClose(values?.eventId);
    }
  };

  const handleGenerateMessage = async () => {
    try {
      if (!formikRef || !formikRef.current) return;
      const formik = getFormikFromRef(formikRef);
      const curEventId = formik.values.eventId ?? eventId;
      const role = formik.values.role ?? message?.role;
      const isGroup = formik.values.isGroup;
      const messageContent = await generateContent({ eventId: curEventId, role, isGroup });
      formik.setFieldValue("message", messageContent);
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  };

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={() => onClose()} className="p-button-text" />
      <Button
        label={isNew ? "Add" : "Save"}
        icon="pi pi-check"
        type="submit"
        onClick={() => {
          const formik = getFormikFromRef(formikRef);
          formik.submitForm();
        }}
        loading={isFetching}
      />
    </div>
  );

  const eventOptions: {
    name: string;
    code: string;
  }[] =
    events?.map((curEvent) => ({
      name: curEvent.title,
      code: curEvent.id,
    })) ?? [];

  return (
    <Dialog
      header={isNew ? "Add new message" : "Edit message"}
      visible={true}
      onHide={() => onClose()}
      footer={footerContent}
    >
      <Formik
        innerRef={formikRef}
        initialValues={
          isNew
            ? {
                title: "",
                message: "",
                eventId: eventId,
                isGroup: false,
                role: null,
                prefferedAsset: null,
              }
            : {
                title: message.title || "",
                message: message.message || "",
                eventId: message.eventId || "",
                isGroup: message.isGroup ?? false,
                role: message.role || null,
                prefferedAsset: message.prefferedAsset || null,
              }
        }
        validationSchema={MessageSchema}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<FormProps>) => (
          <Form>
            <div className="py-4">
              <JInputText id="title" title="Title" placeholder="Title" className="w-full" />
              <JDropdown
                id="eventId"
                title="Event"
                options={eventOptions}
                optionLabel="name"
                optionValue="code"
                onChange={(e) => {
                  props.setFieldValue("eventId", e.value);
                }}
              />
              {!props.values.isGroup && isGroupableEvent(events, props.values.eventId) && (
                <JDropdown
                  showClear
                  id="role"
                  options={promptsRoles}
                  title="Role"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="<role>"
                  className="w-full"
                  onChange={(e) => {
                    props.setFieldValue("role", e.value);
                  }}
                />
              )}
              {isGroupableEvent(events, props.values.eventId) && (
                <div className="flex items-center gap-2 py-4">
                  <span className="">Group message</span>
                  <Checkbox
                    inputId="isGroup"
                    name="isGroup"
                    value={true}
                    checked={props.values.isGroup}
                    onChange={(e) => {
                      const isChecked = e.checked;
                      props.setFieldValue("isGroup", isChecked);
                      props.setFieldValue("role", null);
                    }}
                  />
                </div>
              )}
              <JTextArea id="message" title="Message" placeholder="Template" className="w-full" rows={6} />
              <Button type="button" onClick={handleGenerateMessage} loading={isPromptFetching}>
                Generate
              </Button>

              <div className="mt-4 flex flex-col gap-4 ">
                <span className="font-bold">Preffered Asset</span>

                {props.values.prefferedAsset ? (
                  <div
                    onClick={() => {
                      setShowSelectGif(true);
                    }}
                    className={classNames("flex w-[max-content] text-center cursor-pointer")}
                  >
                    <Image width="200px" src={props.values.prefferedAsset.s3_url} />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setShowSelectGif(true);
                    }}
                    className={classNames(
                      "p-6 border border-dashed mt-3 cursor-pointer rounded-md w-[200px] h-[200px] flex justify-center items-center"
                    )}
                  >
                    Select GIF
                  </div>
                )}
              </div>

              <SelectGifDialog
                show={showSelectGif}
                onSelect={(_, asset) => {
                  props.setFieldValue("prefferedAsset", asset);
                  console.log({ asset });
                  setShowSelectGif(false);
                }}
                eventId={props.values.eventId}
                curGifId={props.values.prefferedAsset && props.values.prefferedAsset.id}
                onClose={() => {
                  setShowSelectGif(false);
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default MessageModal;
