import { useAssetContext } from "@/containers/Assets/AssetContext";
import { AssetDTO } from "@/containers/Assets/types";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { classNames } from "primereact/utils";
import { useEffect } from "react";

function SelectGifDialog({
  onSelect,
  curGifId,
  onClose,
  eventId,
  show,
}: {
  show?: boolean;
  eventId: string;
  curGifId: string | null;
  onSelect: (id: string, asset: AssetDTO) => void;
  onClose: () => void;
}) {
  const { assets, getAssets } = useAssetContext();

  useEffect(() => {
    getAssets(eventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  return (
    <Dialog closable header="Select GIF" onHide={onClose} visible={show}>
      <div className="max-h-[50vh] overflow-auto py-4">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "max-content max-content max-content max-content max-content",
            gridGap: "1rem",
          }}
        >
          {assets?.length > 0 &&
            assets.map((asset) => (
              <div
                key={asset.id}
                onClick={() => onSelect(asset.id, asset)}
                className={classNames(
                  "mb-3 text-center border-2 p-2 border-transparent",
                  asset.id === curGifId && " border-white"
                )}
              >
                <Image width="100px" height="100px" src={asset.s3_url} />
              </div>
            ))}
        </div>
      </div>
    </Dialog>
  );
}

export default SelectGifDialog;
