import { Form, Formik, FormikProps } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { classNames } from "primereact/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import "./NotificationEditModal.scss";
import JDropdown from "../../../../base/JDropdown/JDropdown";
import { useNotificationsContext } from "../../../Notifications/NotificationContext";
import { useMessagesContext } from "../../../Messages/MessagesContext";
import { NotificationSchema } from "../../../Notifications/schemas";
import { JTodayGreetings } from "../../../../types";
import { errorOrDefault, getFormikFromRef } from "@/utils";
import { Checkbox } from "primereact/checkbox";
import JTextArea from "@/base/JTextArea/JTextArea";
import { useUserContext } from "@/containers/Users/UserContext";
import { toast } from "react-toastify";
import SelectGifDialog from "@/components/SelectGifDialog";

interface FromProps {
  resourceId: string;
  messageId: { code: string; name: string };
  isCustomMessage: boolean;
  customMessageContent: string | null;
}

const NotificationEditModal = ({
  item,
  onClose,
  cb,
}: {
  item: JTodayGreetings;
  onClose: () => void;
  cb: () => void;
}) => {
  const formikRef = useRef(null);
  const [showSelectGif, setShowSelectGif] = useState(false);
  const { generateContent, isPromptFetching } = useUserContext();

  const { updateNotification, isFetching } = useNotificationsContext();
  const { messages, getMessages } = useMessagesContext();

  const messagesList = useMemo(
    () =>
      messages?.map(({ id: code, title: name }) => ({
        code,
        name,
      })) || [],
    [messages]
  );

  useEffect(() => {
    getMessages(item.event.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.event.id]);

  const onSubmit = async ({
    messageId: { code: messageId },
    resourceId,
    customMessageContent,
  }: {
    messageId: { code: string; name: string };
    resourceId: string;
    customMessageContent: string | null;
  }) => {
    try {
      await updateNotification(item.id, { messageId, resourceId, customMessageContent, channelId: item.channelId });
      toast.success("Notification updated");
    } catch (err) {
      toast.error(errorOrDefault(err, "Notification update failed"));
    }
    cb && cb();
    onClose();
  };

  const handleGenerateMessage = async () => {
    try {
      const formik = getFormikFromRef(formikRef);
      const curEventId = item.event.id;
      const messageContent = await generateContent({
        eventId: curEventId,
      });
      formik.setFieldValue("customMessageContent", messageContent);
    } catch (err) {
      toast.error("Error generating message");
      console.error(err);
    }
  };

  const footerContent = (
    <div className="pt-4">
      <Button label="Cancel" icon="pi pi-times" onClick={onClose} className="p-button-text" />
      <Button
        label={"Save"}
        icon="pi pi-check"
        type="submit"
        onClick={() => {
          const formik = getFormikFromRef(formikRef);
          formik.submitForm();
        }}
        loading={isFetching}
      />
    </div>
  );

  return (
    <Dialog
      header={"Edit notification"}
      visible={true}
      onHide={onClose}
      footer={footerContent}
      appendTo={"self"}
      className="overflow-auto"
    >
      <Formik
        innerRef={formikRef}
        initialValues={{
          resourceId: item.resourceId,
          messageId: { code: item.messageId, name: item.messageTitle },
          isCustomMessage: item.customMessageContent ? true : false,
          customMessageContent: item.customMessageContent,
        }}
        enableReinitialize
        validationSchema={NotificationSchema}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<FromProps>) => {
          return (
            <Form>
              <div className="flex items-center gap-2 py-4">
                <span className="">Custom Message</span>
                <Checkbox
                  inputId="isCustomMessage"
                  name="isCustomMessage"
                  value={true}
                  checked={props.values.isCustomMessage}
                  onChange={(e) => {
                    const isChecked = e.checked;
                    const curMessageId = props.values.messageId?.code;

                    if (!isChecked) {
                      props.setFieldValue("customMessageContent", null);
                    }

                    if (isChecked) {
                      props.setFieldValue(
                        "customMessageContent",
                        messages?.find((m) => m.id === curMessageId)?.message
                      );
                    }

                    props.setFieldValue("isCustomMessage", isChecked);
                  }}
                />
              </div>
              {props?.values?.isCustomMessage ? (
                <div className="py-4">
                  <JTextArea id="customMessageContent" name="customMessageContent" title="Custom message content" />
                  <Button type="button" onClick={handleGenerateMessage} loading={isPromptFetching}>
                    Generate
                  </Button>
                </div>
              ) : (
                <JTextArea
                  disabled
                  id="messageContent"
                  value={messages?.find((m) => m.id === props?.values?.messageId?.code)?.message}
                  name="messageContent"
                  title="Message content"
                />
              )}

              {!props?.values?.isCustomMessage && (
                <div className="py-4">
                  <JDropdown id="messageId" title="Message" options={messagesList} optionLabel="name" />
                </div>
              )}
              {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
              <div className="resource-container py-4">
                {props.errors.resourceId && (
                  <sub className="p-1 text-pink-600">{props?.errors?.resourceId as string}</sub>
                )}
                <div>
                  <div
                    onClick={() => {
                      setShowSelectGif(true);
                    }}
                    className={classNames("mb-3 flex w-[max-content] text-center border-2")}
                  >
                    <Image width="100px" src={item.resourceUrlMin} />
                  </div>
                  <SelectGifDialog
                    show={showSelectGif}
                    onSelect={(id) => {
                      props.setFieldValue("resourceId", id);
                      setShowSelectGif(false);
                    }}
                    eventId={item.event.id}
                    curGifId={props.values.resourceId}
                    onClose={() => {
                      setShowSelectGif(false);
                    }}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default NotificationEditModal;
